import { EColors } from "common/styles";
import { Styled } from "components/Styled";
import Slider from "react-slick";
import styled from "styled-components";

export const Section = styled.section`
  position: relative;
  z-index: 2;
  padding-top: 140px;
  padding-bottom: 140px;
  background-color: ${EColors.bg_light};
  @media screen and (max-width: 1000px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;
export const Container = styled.div`
  width: 100%;
  padding: 0 72px;
  max-width: 1440px;
  margin: 0 auto;
  @media screen and (max-width: 767px) {
    padding: 0 16px;
  }
`;

export const MiddleLine = styled(Styled.Hr)`
  display: none;
  width: 776px;
  margin-left: auto;
  @media screen and (max-width: 999px) {
    display: block;
  }
`;
export const SwiperContainer = styled(Slider)`
  position: relative;
  padding-bottom: 1px;
  width: 100%;
  background: ${EColors.bg_light};

  & .swiper-wrapper {
    @media screen and (max-width: 999px) {
      margin-bottom: 28px;
    }
  }
`;
export const ImageSwiper = styled.img`
  width: 1002px;
  height: 668.25px;
  object-fit: cover;
  @media screen and (max-width: 1199px) {
    width: 620px;
    height: 60vh;
  }

  @media screen and (max-width: 767px) {
    width: 348px;
    height: 231.66px;
  }
  @media screen and (max-width: 450px) {
    width: 100vw;
    height: 231.66px;
  }
`;

export const ButtonWrapperLeft = styled.div`
  position: absolute;
  top: 50%;
  left: 28px;
  z-index: 1;
  @media screen and (max-width: 999px) {
    display: none;
  }
`;

export const ButtonWrapperRight = styled.div`
  position: absolute;
  top: 50%;
  right: 28px;
  z-index: 1;
  @media screen and (max-width: 999px) {
    display: none;
  }
`;

export const ImageContainer = styled.div`
  text-decoration: none;
  margin-left: 10px;
  height: 668.25px;
  @media screen and (max-width: 1199px) {
    height: 60vh;
  }

  @media screen and (max-width: 767px) {
    height: 231.66px;
  }

  @media screen and (max-width: 450px) {
    width: 100vw;
    height: 231.66px;
    margin-left: 0;
  }
`;
export const StyledButtonWrapperMob = styled.div`
display:flex;
align-items: center;
gap:40px;
width:100%;
margin-top:28px;
`