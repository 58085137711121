import { Png } from "common/assets";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  align-self: center;
  gap: 24px;
  padding: 0px 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media screen and (min-width: 768px) {
    gap: 48px;
    padding: 0px 72px;
  }
  @media screen and (min-width: 1440px) {
    max-width: 1440px;
    /* margin-top: 140px; */
  }
`;

export const ContantContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  @media screen and (min-width: 999px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const LeftImage = styled.img`
  width: 100%;
  max-width: 636px;
  @media screen and (max-width: 999px) {
    max-width: 100%;
  }
`;

export const RightImage = styled.img`
  width: 100%;
  max-width: 636px;
  margin-top: 20px;
  @media screen and (min-width: 768px) {
    margin-top: 0;
  }
  @media screen and (max-width: 999px) {
    max-width: 100%;
  }
`;
export const FlexHalfWrapperLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  @media screen and (min-width: 999px) {
    width: 50%;
    align-items: flex-start;
    gap: 44px;
  }
`;
export const FlexHalfWrapperRight = styled(FlexHalfWrapperLeft)`
  flex-direction: column-reverse;
  align-items: center;
  @media screen and (min-width: 999px) {
    flex-direction: column;
    align-items: flex-end;
  }
`;
