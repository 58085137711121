
import { IntervalNumber } from 'components/IntervalNumber'
import { EColors } from 'common/styles'
import { Styled } from 'components/Styled'
import { Container, FlexWrapperStatistic, FlexWrapperHalfStatistic, TitleNumber, TitleNumberText } from './styled'
import { StatisticProps } from './types'
import { diffYears } from 'common/helpers/diffYears'
import { Fade } from 'react-awesome-reveal'
import { useIntersection, useResolution } from 'common/hooks'
import { useRef } from 'react'

const FOUNDED_DATE = [2019, 7, 25]

export const Statistic = ({ numbers, number, title, info }: StatisticProps) => {
  const { isMinScreen1000 } = useResolution()
  const ref = useRef(null)
  const isInteract = useIntersection({ element: ref })
  return (
    <>
      <Fade
        cascade
        triggerOnce
        direction="up"
        delay={800}
        duration={500}
        damping={0.5}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: isMinScreen1000 ? "30px" : "20px",
          opacity: isInteract? "1":"0",
        }}
      >
        <FlexWrapperStatistic ref={ref}>
          {/* Left */}
          <FlexWrapperHalfStatistic>
            <TitleNumber >
             {numbers && (<IntervalNumber numbers={numbers} />)}
              {number && diffYears(FOUNDED_DATE)}
            </TitleNumber>
            <TitleNumberText >{title}</TitleNumberText >
          </FlexWrapperHalfStatistic>

          {/* Right */}
          <FlexWrapperHalfStatistic>
            <Styled.PBody2SB color={EColors.primary_200}>
              {info}
            </Styled.PBody2SB>
          </FlexWrapperHalfStatistic>
        </FlexWrapperStatistic>
        <Styled.Hr />
      </Fade>
    </>
  )
}
