import { Png } from "common/assets";
import { EColors } from "common/styles";
import { Styled } from "components/Styled";
import styled from "styled-components";

export const Section = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), url(${Png.BookBg});
  
  /* background-attachment: fixed; */
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;
  overflow: hidden;
   @media screen and (min-width: 551px) {
  background-image: url(${Png.BgRestaurant});
  background-image: -webkit-image-set(url(${Png.BookBackground}) 1x,url(${Png.BookBackgroundBig}) 2x);
  background-image: -webkit-image-set(url(${Png.BookBackgroundWeb}) 1x,url(${Png.BookBackgroundWebBig}) 2x);
  background-attachment: fixed;
  min-height: 900px;
  }
`;

export const Title = styled(Styled.H3)`
  color: ${EColors.white};
  width: 100%;
  max-width: 1076px;
  text-align: center;
  padding: 0 16px;
  font-weight: 400;
  font-size: 26px;
  line-height: 116%;
  text-align: center;

  @media screen and (min-width: 768px) {
    padding: 0 72px;
  }
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    font-size: calc((100vw - 768px) / (1440 - 768) * (42 - 26) + 26px);
  }
  @media screen and (min-width: 1440px) {
    font-size: 42px;
  }
`;
