import { EColors } from 'common/styles'
import { Styled } from 'components/Styled'
import { Container, ContentWarpper, InfoWrapper, TitleWrapper } from './styled'
import { RowInfoProps } from './types'

export const RowInfo = ({ Info, Title }: RowInfoProps) => {
  return (
    <>
      <Container>
        <ContentWarpper>
          {/* Left */}
          <TitleWrapper>{Title}</TitleWrapper>

          {/* Right */}
          <InfoWrapper>
            <Styled.PBody2SB color={EColors.primary_500}>
              {Info}
            </Styled.PBody2SB>
          </InfoWrapper>
        </ContentWarpper>
        <Styled.Hr />
      </Container>
    </>
  )
}
