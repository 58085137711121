import { Config } from 'common/config'
import { useResolution } from 'common/hooks'
import { Header } from 'modules/Header'

import { useTranslation } from 'react-i18next'
import { Component } from './components'
import {
  ButtonDown,
  ButtonsContainer,
  Container,
  ContantContainer,
  MottoInfo,
  MottoInfoWrapper,
} from './styled'

export const Main = () => {
  const { t } = useTranslation()
  const { isMinScreen1000 } = useResolution();

  const onBottomClick = () => {
    window.location.href = `#${Config.Section.bookTable}`
  }

  return (
    <>
      <Container>
        <Header />
        <ContantContainer>
          <Component.Motto />
          <Component.Franchise />
          <MottoInfoWrapper>
            <MottoInfo>{t('motto.info')}</MottoInfo>
            {isMinScreen1000 && <Component.LoadApp />}
          </MottoInfoWrapper>
          <ButtonsContainer>
            <ButtonDown type="button" onClick={onBottomClick} />
            {!isMinScreen1000 && <Component.LoadApp />}
          </ButtonsContainer>

        </ContantContainer>
        
      </Container>
    </>
  )
}
