export enum EColors {
  black = '#000000',
  white = '#ffffff',

  neutral_100 = '#F8F8F8', 
  neutral_300 = '#DFDFDF',
  neutral_400 = '#CACACA',
  neutral_600 = '#A0A0A0',
  neutral_800 = '#222222',

  primary_100 = '#C5AAAA',
  primary_200 = '#A18282',
  primary_500 = '#B00000',
  

  bg_dark = '#FBF3E6',
  bg_light = '#FCF9F0',

  filter_white = ' invert(100%) sepia(0%) saturate(0%) hue-rotate(53deg) brightness(106%) contrast(101%)',
  filter_black = 'invert(0%) sepia(92%) saturate(31%) hue-rotate(157deg) brightness(92%) contrast(107%)',
  filter_primary_100 = 'invert(78%) sepia(7%) saturate(50%) hue-rotate(180deg) brightness(93%) contrast(90%)',
  filter_primary_500 = 'invert(100%) sepia(0%) saturate(0%) hue-rotate(53deg) brightness(106%) contrast(101%)',
  filter_primary_600 = 'invert(18%) sepia(36%) saturate(551%) hue-rotate(78deg) brightness(96%) contrast(86%)',
  filter_neutral_600 = 'invert(70%) sepia(0%) saturate(45%) hue-rotate(257deg) brightness(91%) contrast(90%)',
}
