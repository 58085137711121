import { LandingContext } from "common/contexts/Landing/Landing";
import { useIntersection, useWindowDimensions } from "common/hooks";
import { Styled } from "components/Styled";
import { baseURL } from "models";
import { GeneralMenu } from "modules/GeneralMenu";
import { useContext, useRef } from "react";
import { Fade } from "react-awesome-reveal";
import {useTranslation } from "react-i18next";
import {
  Container,
  ContantContainer,
  FlexHalfWrapperLeft,
  FlexHalfWrapperRight,
  LeftImage,
  RightImage,
} from "./styled";


export const General = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { landing } = useContext(LandingContext);
  const language = localStorage.getItem("Localization") ?? "uk";
  const ref = useRef(null)
  const isInteract = useIntersection({ element: ref })

  return (
    <>
      <Container >
        <Fade direction="up" triggerOnce   delay={500} cascade style={{ opacity: isInteract ? "1" : "0", }} >
          <Styled.H2 ref={ref}>{t("little.about.us")}</Styled.H2>
          <ContantContainer>
            {/* left */}
            <FlexHalfWrapperLeft>
              <LeftImage
                src={`${baseURL}/${landing?.headerPhoto}`}
                alt="image restaurant"
              />
              {width < 768 && (
                <Styled.FlexWrapper direction="column" gap={24}>
                  <Styled.PBody1>
                    {language === "uk" ? landing?.description[0].value : landing?.description[1].value}
                  </Styled.PBody1>
                </Styled.FlexWrapper>
              )}
            </FlexHalfWrapperLeft>

            {/* right */}
            <FlexHalfWrapperRight>
              <RightImage
                src={`${baseURL}/${landing?.mainImage}`}
                alt="image restaurant"
              />
             
              {width >= 768 && (
                <Styled.FlexWrapper direction="column" gap={24}>
                  <Styled.PBody1>
                    {language === "uk" ? landing?.description[0].value : landing?.description[1].value}
                  </Styled.PBody1>
                </Styled.FlexWrapper>
              )}
              <GeneralMenu
                hideOurMenu
                style={{
                  flexDirection:
                    width < 375 ? "column-reverse" : "row-reverse",
                }}
              />
            </FlexHalfWrapperRight>
          </ContantContainer>
        </Fade>
      </Container>
    </>
  );
};
