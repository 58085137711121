import { EColors } from "common/styles";
import { Styled } from "components/Styled";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: ${EColors.bg_light};
  @media screen and (max-width: 768px) {
    padding: 0px 16px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 768px) {
     width: 651px;
  }
  @media screen and (min-width: 1440px) {
     width: 855px;
  }
`;

export const ButtonSuccess = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 216px;
  height: 216px;
  border: 1px solid;
  border-color: ${EColors.neutral_800};
  border-radius: 50%;
  margin-top: 34px;
  margin-bottom: 36px;
  transition: all 0.4s linear;
  @media screen and (max-width: 768px) {
    margin-top: 114px;
    margin-bottom: 56px;
    width: 156px;
    height: 156px;
  }
  &:hover {
    border-color: transparent;
  }
  img {
    width: 111.08px;
    height: 71.67px;
    @media screen and (max-width: 768px) {
      width: 100.75px;
      height: 65px;
    }
  }
`;
export const SuccessText = styled(Styled.PBody1)`
  margin-top: 48px;
  margin-bottom: 36px;
  text-align: center;
  @media screen and (max-width: 768px) {
    margin-top: 114px;
    margin-bottom: 24px;
    align-self: stretch;
  }
`;
