import moment from "moment";

export const diffYears = (founded_date: Array<number>) => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    const a = moment([year, month, day]);
    const b = moment(founded_date);

    const years = a.diff(b, 'year');
    return years
}