import { LandingContext } from 'common/contexts/Landing/Landing'
import { EColors } from 'common/styles'
import { Button } from 'components/buttons'
import { RowInfo } from 'components/RowInfo'
import { Styled } from 'components/Styled'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { TitleInfo } from '../../styled'

export const Info = () => {
  const { t } = useTranslation()
  const { landing } = useContext(LandingContext);
  const language = localStorage.getItem("Localization") ?? "uk";

  const data =  [
      {
        title: t('addresses'),
        Info: (
          <>
            <TitleInfo color={EColors.primary_500}>
              {t('contact.address')}
            </TitleInfo>
          </>
        ),
      },
      {
        title: t('work.time'),
        Info: (
          <Styled.FlexWrapper direction='column' gap={24} align='flex-start'>
            <TitleInfo color={EColors.primary_500}>
              {language === "uk" ? landing?.workingTime[0].day[0].value : landing?.workingTime[0].day[1].value}
              &nbsp;
              {`${landing?.workingTime[0].start}` + "-" + `${landing?.workingTime[0].end}`}
            </TitleInfo >
            {landing?.workingTime[1] && (<TitleInfo color={EColors.primary_500}>
              {language === "uk" ? landing?.workingTime[1]?.day[0].value : landing?.workingTime[1]?.day[1].value}
              &nbsp;
              {`${landing?.workingTime[1]?.start}` + "-" + `${landing?.workingTime[1]?.end}`}
            </TitleInfo>)
            }
          </Styled.FlexWrapper>
        ),
      },
      {
        title: t('phone'),
        Info: (
          <>
            <TitleInfo >
              {t('contact.phone')}
            </TitleInfo >
          </>
        ),
      },
      {
        title: t('social.media'),
        Info: (
          <>
            <Button.SocialMedia />
          </>
        ),
      },
    ]
  

  return (
    <>
      <Styled.FlexWrapper
        width="100%"
        direction="column"
        gap={28}
        style={{ marginTop: '48px' }}>
        {data.map((item, index) => (
          <RowInfo
            key={index}
            Info={item.Info}
            Title={
              <Styled.PBody2SB color={EColors.primary_200}>
                {item.title}
              </Styled.PBody2SB>
            }
          />
        ))}
      </Styled.FlexWrapper>
    </>
  )
}
