import { Config } from 'common/config'
import { MenuContext } from 'common/contexts/Menu'
import { useResolution } from 'common/hooks'
import { FlexWrapper } from 'components/Styled/Styled'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Component } from '..'
import { Container, Item, Link, StyledGeneralMenu } from './styled'

export const Menu = () => {
  const { t } = useTranslation()
  const { setIsOpen } = useContext(MenuContext)
  const { isMinScreen1000 } = useResolution()

  const items = [
    {
      title: t('about.us'),
      href: `#${Config.Section.aboutUs}`,
    },

    {
      title: t('other.bookmarks'),
      href: `#${Config.Section.anotherRestaurants}`,
    },
    {
      title: t('contacts'),
      href: `#${Config.Section.contacts}`,
    },
  ]

  const onItemClick = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Container>
        {!isMinScreen1000 && (<StyledGeneralMenu/>)}
        <FlexWrapper direction="column" gap = {isMinScreen1000 ? 56:28}  >
          {items.map((item, index) => (
            <Link href={item.href} key={index} onClick={onItemClick}>
              <Item>{item.title}</Item>
            </Link>
          ))}
        </FlexWrapper>

        <Component.Footer />
      </Container>
    </>
  )
}
