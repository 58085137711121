import { Config } from 'common/config'
import { Button } from 'components/buttons'
import { Styled } from 'components/Styled'
import { useTranslation } from 'react-i18next'
import { Container, ItemsList, Link, LinkItem } from './styled'

export const Menu = () => {
  const { t } = useTranslation()
  const items = [
    {
      title: t('about.us'),
      href: `#${Config.Section.aboutUs}`,
    },
    {
      title: t('other.bookmarks'),
      href: `#${Config.Section.anotherRestaurants}`,
    },
    {
      title: t('contacts'),
      href: `#${Config.Section.contacts}`,
    },
  ]
  return (
    <>
      <Container>
        {/* Menu list */}
        <ItemsList>
          {items.map((item, index) => (
            <LinkItem key={index}>
              <Link href={item.href} >{item.title}</Link>
            </LinkItem >
          ))}
        </ItemsList>
      </Container>
    </>
  )
}
