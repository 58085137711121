import { EColors, EFont } from "common/styles";
import { FONT } from "common/utils";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 1440px) {
    height: 168px;
  }
`;

export const FranchiseWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  opacity: 0;
  transform: translateY(-100%);
   animation: appearence 0.5s linear 0.3s forwards;
  @keyframes appearence {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
export const FranchiseText = styled.span`
  font-family: ${EFont.Playfair_Display};
  font-weight: 600;
  font-size: 46px;
  color: ${EColors.neutral_800};
  opacity: 0.06;
  line-height: 100%;
  text-align: center;
 
  @media screen and (min-width: 375px) and (max-width: 767px) {
    font-size: calc((100vw - 375px) / (767 - 375) * (98 - 46) + 46px);
  }
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    font-size: calc((100vw - 768px) / (1439 - 768) * (168 - 83) + 83px);
  }
  @media screen and (min-width: 1440px) {
    ${FONT({
      size: "168px",
      color: EColors.neutral_800,
      weight: "600",
      family: EFont.Playfair_Display,
    })}
    line-height: 172px;
    z-index: 2;
    position: absolute;
    top: 0;
    opacity: 0.04;
  }
`;

