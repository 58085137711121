import { EColors } from "common/styles";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
    @media screen and (max-width: 999px) {
  gap: 20px;
}
`;
export const FlexWrapperStatistic = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
  align-items: baseline;
  @media screen and (max-width: 999px) {
    flex-direction: column;
    gap: 4px;
  }
`;
export const TitleNumber = styled.p`
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 30px;
  /* line-height: 32px;
  letter-spacing: 106%; */
  color: ${EColors.primary_500};
  @media screen and (min-width: 460px) and (max-width: 1439px) {
    font-size: calc((100vw - 460px) / (1439- 460) * (68 - 30) + 30px);
  }
@media screen and (min-width: 551px) {
    font-size: 46px;
  }
  @media screen and (min-width: 1000px) {
    font-size: 68px;
    line-height: 69px;
  }
`;
export const TitleNumberText = styled.span`
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: ${EColors.primary_500};
  @media screen and (min-width: 460px) and (max-width: 1439px) {
    font-size: calc((100vw - 460px) / (1439- 460) * (28 - 16) + 16px);
  }

  @media screen and (min-width: 1440px) {
    font-size: 28px;
    line-height: 100.8%;
  }
`;
export const FlexWrapperHalfStatistic = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: baseline;
  gap: 15px;
  @media screen and (min-width: 1000px) {
    width: 50%;
  }
`;
