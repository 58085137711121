import { useTranslation } from 'react-i18next'
import { Component } from '..'
import { TStatistic } from '../Statistic/types'
import { Container } from './styled'


export const Statistics = () => {
  const { t } = useTranslation()

  return (
    <>
      <Container>
        {(t('statistics', { returnObjects: true }) as TStatistic[]).map((statistic, index) => (
          <Component.Statistic
            {...statistic}
            title={t(statistic.title)}
            info={t(statistic.info)}
            key={index}
          />
        ))}
      </Container>
    </>
  )
}
