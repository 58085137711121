import { Config } from 'common/config'
import { useIntersection } from 'common/hooks'
import { Styled } from 'components/Styled'
import { useRef } from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import { Component } from './components'
import { ContentWrapper, Section } from './styled'

export const Contacts = () => {
  const { t } = useTranslation()
  const ref = useRef(null)
  const isInteract = useIntersection({ element: ref })
  
  return (
    <>
      <Section id={Config.Section.contacts}>
        <ContentWrapper>
          <Fade direction="up" triggerOnce cascade damping={0.5} style={{ opacity: isInteract ? "1" : "0", }}>
            <Styled.H2 ref={ref}>{t('contacts')}</Styled.H2>
          <Component.Map />
          <Component.Info />
          </Fade>
        </ContentWrapper>
      </Section>
    </>
  )
}
