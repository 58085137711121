import { EColors } from 'common/styles'
import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  height: 100%;
  padding-top:140px;
  background-color: ${EColors.bg_light};
  position: relative;
  overflow:hidden;
  @media screen and (max-width: 1000px) {
    padding-top:60px;
  }
  /* @media screen and (min-width: 1440px) {
    height: 3719px;
  } */

`

export const Content = styled.div`
  height: 100%;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 140px;
  @media screen and (max-width: 767px) {
    gap: 60px;
  }
`
