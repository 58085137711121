export const General = {
  generalPolicyPrivacy: '#',
  policyPrivacy: '#',
  instagram: 'https://www.instagram.com/para_janov/',
  facebook: 'https://www.facebook.com/parajanovportwinebar/',
  googleApiKey: 'AIzaSyAfq0_75Gl5XXb9En8dlz7LdPQhftS8XH0',
  monspius: 'https://monspius.com.ua/',
  babogardens: 'https://babogardens.com.ua/',
  libraria: 'https://libraria.com.ua/',
  centaura:'https://centaura.com.ua/',
  phone: '(067) 901-19-24',
}
