import { useResolution } from "common/hooks";
import { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Header } from "modules/Header";
import { Component } from "./components";
import { Container } from "./styled";

export const Menu = () => {
  const { isMinScreen1000 } = useResolution();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 0);
  }, []);

  return (
    <>
      <Container style={{ opacity: isVisible ? 1 : 0 }}>
        <Fade triggerOnce cascade duration={500} >
          <Header />
          {isMinScreen1000 && <Component.Background />}
          <Component.Menu />
        </Fade>
      </Container>
    </>
  );
};
