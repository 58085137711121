import { EColors } from "common/styles";
import styled from "styled-components";

export const Container = styled.header`
  width: 100%;
  padding: 32px 72px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items:center;
  background-color: ${EColors.bg_light};
  z-index:10;
  @media screen and (max-width: 1199px) {
    padding: 16px;
  }
`;

export const Logo = styled.img`
  width: 196.86px;
  height: 71.02px;
  @media screen and (max-width: 1999px) {
    width: 132.32px;
    height: 48px;
  }
`;
