import { EColors, EFont } from "common/styles";
import styled from "styled-components";
import { TFlexWrapper, THr, TText, TTextProps } from "./types";

export const FlexWrapper = styled.div<TFlexWrapper>`
  display: flex;
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
  flex-direction: ${({ direction }) => direction || "row"};
  align-items: ${({ align }) => align || "center"};
  justify-content: ${({ justify }) => justify || "center"};
  gap: ${({ gap }) => (gap ? `${gap}px` : "0px")};
  flex-wrap: ${({ wrap }) => wrap || "nowrap"};
  max-width: 100%;
`;

export const CursorPointerWrapper = styled.div`
  cursor: pointer;
`;

export const Hr = styled.hr<THr>`
  width: 100%;
  height: 1px;
  background-color: ${({ color }) => color || EColors.neutral_300};
  border: none;
  margin-top: ${({ marginTop }) => marginTop};
`;

const Text = (type: TText) => styled(type)<TTextProps>`
  text-align: ${({ align }) => align || "left"};
  color: ${({ color }) => color || EColors.neutral_800};
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  max-width: 100%;
`;

export const H1 = styled(Text("h1"))`
  font-size: 100px;
  font-family: ${EFont.Playfair_Display};
  line-height: 90px;
  @media screen and (max-width: 459px) {
    font-size: 36px;
    line-height: 106%;
    letter-spacing: 0.364px;
  }
  @media screen and (min-width: 460px)and (max-width: 1439px) {
  font-size: calc((100vw - 460px) / (1439 - 460) * (100 - 36) + 36px);
  line-height: 106%;
  }
`;

export const H2 = styled(Text("h2"))`
  font-size: 68px;
  font-family: ${EFont.Playfair_Display};
  line-height: 69px;
   @media screen and (max-width: 459px) {
    font-size: 30px;
    line-height: 106%;
  }
  @media screen and (min-width: 460px) and (max-width: 1439px) {
  font-size: calc((100vw - 460px) / (1439 - 460) * (68 - 30) + 30px);
  line-height: 108%;
  }
`;

export const H3 = styled(Text("h3"))`
  font-size: 42px;
  font-family: ${EFont.Playfair_Display};
  line-height: 50px;

    @media screen and (max-width: 550px) {
    font-size: 26px;
    line-height: 30px;
  }
`;

export const H4 = styled(Text("h4"))`
  font-size: 28px;
  font-family: ${EFont.Playfair_Display};
  line-height: 33.6px;
`;

export const PBody1 = styled(Text('p'))`
  font-size: 18px;
  font-family: ${EFont.Montserrat};
  line-height: 26px;

  @media screen and (max-width: 550px) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const PBody1SB = styled(PBody1)`
  font-weight: 600;
`;

export const PBody2 = styled(Text("p"))`
  font-size: 16px;
  font-family: ${EFont.Montserrat};
  line-height: 23.5px;

  @media screen and (max-width: 550px) {
    font-size: 14px;
    line-height: 19.6px;
  }
`;

export const PBody2SB = styled(PBody2)`
  font-weight: 600;
`;

export const ButtonText = styled(Text("span"))`
  font-size: 16px;
  font-family: ${EFont.Montserrat};
  font-weight: 500;
 @media screen and (max-width: 767px) {
      font-size: 14px;
    }   
`;

export const Caption = styled(Text("caption"))`
  font-size: 14px;
  font-family: ${EFont.Montserrat};
`;

export const CaptionSB = styled(Caption)`
  font-weight: 600;
`;
