import { useIntersection } from 'common/hooks'
import { CommonUtils } from 'common/utils'
import { useEffect, useRef, useState } from 'react'

type NumberProps = {
  numbers: number[]
  time?: number //   in ms
  iteration?: number
}

const DEFAULT_TIME = 500
const ITERATION = 200


const generateNumberByDate = (initNumber: number, incrementedNumbers: number[], dateStart: string): number => {
  const DAY_IN_MILISECONDS = 86400000
  let value = initNumber

  const start = new Date(dateStart)
  const now = new Date()

  const interval = now.getTime() - start.getTime()

  const dayInterval = Math.floor(interval / DAY_IN_MILISECONDS)

  for (let i = 0; i < dayInterval; i++) {
    const index = i % incrementedNumbers.length
    const incrementedNumber = incrementedNumbers[index]

    value += incrementedNumber;
  }

  return value
}

export const IntervalNumber = ({
  numbers,
  time = DEFAULT_TIME,
  iteration = ITERATION,
}: NumberProps) => {
  const [currentNumber, setCurrentNumber] = useState<number>(0)
  const ref = useRef(null)



  const randomNumber = generateNumberByDate(numbers[0], numbers, "2019-08-25T08:33:04.393Z");

  
  useEffect(() => {
    setCurrentNumber(randomNumber )
  }, [randomNumber]
  )
  

  return <span ref={ref}>{CommonUtils.formatNumber(currentNumber)}</span>
}
