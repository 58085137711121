import BgRestaurant from './sources/bgRestaurant.png'
import BgNoise from './sources/bgNoise.png'
import BgLineAtmosphere from './sources/bgLineAtmosphere.png'
import BookBackground from './sources/bookBackground.png'
import BookBackgroundBig from './sources/bookBackground@2x.png'
import BookBackgroundWeb from './sources/bookBackground.webp'
import BookBackgroundWebBig from './sources/bookBackground@2x.webp'
import BookBg from './sources/bookBg.png'
import AboutUs1 from './sources/about_us_1.png'
import AboutUs2 from './sources/about_us_2.png'
import AboutUs2Desk from './sources/about_us_2Desk.png'
import GeneralMenu from './sources/general_menu.png'
import OurKitchen from './sources/our_kitchen.png'
import Libraria from './sources/libraria.png'
import MonsPins from './sources/mons_pins.png'
import BaboGarden from './sources/babo_garden.png'
import Centaur from './sources/centaur.png'
import Swiper from './sources/swiper.png'
import Swiper1 from './sources/swiper1.png'
import Bike from './sources/bike.png'
import BgParajanov from './sources/bg-parajanov.png'
import ModalBg from './sources/modal_bg.png'
import App from './sources/app_store.png'
import Google from './sources/google_play.png'



export const Png = {
  BgRestaurant,
  BgNoise,
  BgLineAtmosphere,
  BookBackground,
  BookBackgroundBig,
  BookBackgroundWeb,
  BookBackgroundWebBig,
  BookBg,
  AboutUs1,
  AboutUs2,
  AboutUs2Desk,
  GeneralMenu,
  OurKitchen,
  Libraria,
  MonsPins,
  BaboGarden,
  Swiper,
  Swiper1,
  Bike,
  BgParajanov,
  Centaur,
  ModalBg,
  App,
  Google
}
