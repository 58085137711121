import { EColors } from "common/styles";
import styled from "styled-components";

export const Container = styled.header`
  width: 100%;
  padding: 32px 72px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${EColors.bg_light};

  @media screen and (max-width: 768px) {
    padding: 16px;

    img {
      width: 198.49px;
      height: 72px;
      @media screen and (max-width: 767px) {
        width: 132.32px;
        height: 48px;
      }
    }
  }
`;
