import { Svg } from 'common/assets'
import { BookContext } from 'common/contexts/Book'
import { useResolution } from 'common/hooks'
import { EColors } from 'common/styles'
import { Button } from 'components/buttons'
import { Styled } from 'components/Styled'
import { useContext, useEffect, useState } from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import { ButtonSuccess, Container, FormContainer, SuccessText, } from './styled'

export const Done = () => {
  const { t } = useTranslation()
  const { isMinScreen1000 } = useResolution();
  const { setIsOpen } = useContext(BookContext)
  const [isVisible, setIsVisible] = useState(false)

  const onBackClick = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true)
    }, 0)
  }, [isVisible])
  return (
    <>
      <Container>
        <FormContainer>
          <Fade triggerOnce>
              <ButtonSuccess>
                <img src={Svg.Vector} alt="vector" />
              </ButtonSuccess>
              <Styled.H2 color={EColors.neutral_800}>{t("success.title")}</Styled.H2>
              <SuccessText>{t("success.text")}</SuccessText>
              <Button.Ellipse onClick={onBackClick}>
                {isMinScreen1000 ? t("back.desk") : t("back.mob")}
              </Button.Ellipse>
          </Fade>
        </FormContainer>
      </Container>
    </>
  )
}
