import { Png } from 'common/assets'
import { useTranslation } from 'react-i18next'
import { Container, ImageBike, MottoText, MottoWrapper } from './styled'

export const Motto = () => {
  const { t } = useTranslation()
 

  return (
    <>
      <Container>
        <MottoWrapper>
          <ImageBike src={Png.Bike} />
          <MottoText>{t('motto.first')}<br />{t('motto.second')}</MottoText>
        </MottoWrapper>
      </Container>
    </>
  )
}
