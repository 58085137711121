import { EColors, EFont } from "common/styles";
import styled from "styled-components";

export const StyledModalLoad = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 343px;
  height: 460px;
  border-radius: 16px;
  background: white;
  padding: 12px 16px 16px;
  z-index: 1001;
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1) 250ms;
  scale: 0;
  opacity: 0;
  animation: load 500ms forwards 250ms;
  animation-delay: 500ms;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  @keyframes load {
    0% {
      scale: 0;
      opacity: 0;
    }
    100% {
      scale: 1;
      opacity: 1;
    }
  }
`;
export const StyledModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  display:flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 0 16px;
  overflow: hidden;
  opacity: 1;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
  &.is-hidden {
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 300ms;
  }
  &.is-hidden > ${StyledModalLoad} {
    transform: scale(0) rotateX(-90deg);
    transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1) 250ms;
  }
`;

export const StyledCloseButton = styled.button`
  border: none;
  outline: none;
  border-radius: 50%;
  background-color: #f8f9fa;
  width: 24px;
  height: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  margin: 0 0 0 auto;
  cursor: pointer;
  & img {
    width: 20px;
    height: 20px;
  }
`;
export const StyledImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 0;
  & img {
    width: 212px;
    height: 317px;
  }
`;
export const StyledBg = styled.div`
  position: absolute;
  bottom: 30px;
  width: 100%;
  height: 192px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.01) 0%,
    rgba(255, 255, 255, 1) 64%
  );
  z-index: 1;
`;
export const StyledContentWrapper = styled.div`
  position: absolute;
  top: 316px;
  z-index: 1;
`;
export const StyledTitle = styled.h4`
  margin: 0;
  font-family: ${EFont.Montserrat};
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  text-align: center;
`;
export const StyledText = styled.p`
  font-family: ${EFont.Montserrat};
  font-weight: 400;
  font-size: 14px;
  color: #8b8b8b;
  text-align: center;
  margin-top: 8px;
`;
export const StyledButtonLoad = styled.button`
  border: none;
  outline: none;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  padding: 10px 8px;
  font-family: ${EFont.Montserrat};
  font-weight: 400;
  font-size: 16px;
  color: ${EColors.white};
  text-align: center;
  background-color: #B00000;
  cursor: pointer;
  transition: 0.3s linear;
  &:hover {
    background-color: #6B0D0D;
  }
`;
