import { EColors } from "common/styles";
import styled from "styled-components";

export const StyledFooter = styled.footer`
  width: 100%;

  background-color: ${EColors.bg_light};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 73px 72px 124px;
  height: 340px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    padding: 52px 72px 40px;
  }
  @media screen and (max-width: 767px) {
    padding: 52px 16px 40px;
  }
  @media screen and (max-width: 1439px) {
    height: auto;
  }
`;
export const StyledFooterMenu = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }

   @media screen and (min-width: 1200px) {
    gap: 40px;
  }
`;
export const FlexWrapperBrand = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 1000px) {
    align-items: center;
  }
`;
export const LogoImg = styled.img`
  width: 198.49px;
  height: 72px;
  @media screen and (max-width: 767px) {
    width: 132.32px;
    height: 48px;
  }
  @media screen and (min-width: 768px) and (max-width: 1440px) {
    width: 13.67vw;
    height: 8vh;
  }
`;
