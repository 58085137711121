import Facebook from './sources/Facebook.svg'
import Instagram from './sources/Instagram.svg'
import ArrowDown from './sources/ArrowDown.svg'
import Cross from './sources/Cross.svg'
import ArrowDownNoLine from './sources/ArrowDownNoLine.svg'
import Menu from './sources/Menu.svg'
import Logo from './sources/Logo.svg'
import Ellipse from './sources/Ellipse.svg'
import FilledEllipse from './sources/FilledEllipse.svg'
import Line from './sources/Line.svg'
import LineMob from './sources/LineMob.svg'
import LineDecoration from './sources/LineDecoration.svg'
import AnimatedLine1 from './sources/animated_line_1.svg'
import AnimatedLine2 from './sources/animated_line_2.svg'
import MenuLine from './sources/menu_line.svg'
import Vector from './sources/vector.svg'
import Error from './sources/error.svg'
import sprite from './sources/sprite.svg'
import Close from './sources/close.svg'


export const Svg = {
  Facebook,
  Instagram,
  ArrowDown,
  Cross,
  ArrowDownNoLine,
  Menu,
  Logo,
  Ellipse,
  FilledEllipse,
  LineMob,
  Line,
  LineDecoration,
  AnimatedLine1,
  AnimatedLine2,
  MenuLine,
  Vector,
  Error,
  sprite,
  Close,
}
