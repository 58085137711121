import { EColors } from "common/styles";
import styled from "styled-components";

export const MenuButton = styled.div`
  border: none;
  border-radius: 100%;
  background-color: ${EColors.bg_dark};
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media screen and (max-width: 768px) {
     width: 48px;
  height: 48px;
  }
  img {
    width: 40% !important;
  }

`;

