import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import { General } from 'common/config/general'
import { mapStyle } from './custom'

const styles = [
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
]

const containerStyle = {
  width: '100%',
  height: '299px',
  marginTop: '48px',
}

const center = {
  lat: 49.84382019812598,
  lng: 24.030912011692923,
}

const zoom = 14

export const Map = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: General.googleApiKey,
  })

  return (
    <>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={zoom}
          options={{
            styles: mapStyle,
            panControl: false,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
          }}
        />
      )}
    </>
  )
}
