import { EColors } from "common/styles";
import { Styled } from "components/Styled";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 1440px) {
    height: 168px;
    /* margin-top: 90px; */
  }
`;

export const MottoWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1124px;
  position: relative;
  opacity: 0;
  transform: translateY(-100%);
  animation: appearence 0.4s linear 0.1s forwards;
  @keyframes appearence {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const MottoText = styled(Styled.H1)`
  color: ${EColors.neutral_800};
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  @media screen and (max-width: 1439px) {
    position: static;
    z-index: 0;
  }
`;

export const ImageBike = styled.img`
  position: absolute;
  top: -77px;
  right: 0;
  z-index: 3;
  opacity: 0;
  transition: transform 0.4s linear;
  animation: appearance 3s linear 0.5s forwards;
  cursor: pointer;
  &:hover {
    transform: rotate3d(0, 1, 1, 20deg);
  }
  @media screen and (max-width: 550px) {
    width: 147px;
    top: -117px;
  }

  @media screen and (min-width: 551px) and (max-width: 1189px) {
    top: -117px;
  }
  @keyframes appearance {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;
