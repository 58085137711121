import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 0px 16px;
  align-self: center;

  display: flex;
  flex-direction: column;
  gap: 30px;
  @media screen and (max-width: 1439px) {
    gap: 20px;
  }
  @media screen and (min-width: 768px) {
    padding: 0px 72px;
  }
`;
