import { LandingContext } from "common/contexts/Landing/Landing";
import { baseURL } from "models";
import { Styled } from "components/Styled";
import { useContext, useRef } from "react";
import { Settings } from "react-slick";
import SlickSlider from "react-slick";
import { Button } from "components/buttons";
import { useResolution } from "common/hooks";
import {
  ButtonWrapperLeft,
  ButtonWrapperRight,
  Container,
  ImageContainer,
  ImageSwiper,
  MiddleLine,
  Section,
  StyledButtonWrapperMob,
  SwiperContainer,
} from "./styled";


export const Decoration = () => {
  const slider = useRef<SlickSlider>(null);
  const lastSlide = useRef<HTMLDivElement>(null);
  const firstSlide = useRef<HTMLDivElement>(null);
  const { isMinScreen1000 } = useResolution();
  const { landing } = useContext(LandingContext);
  const pictures = landing?.mainSlider;
  const images = [...pictures]

  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    centerMode: true,
    adaptiveHeight: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 449,
        settings: {
          slidesToShow: 1,
          infinite: true,
          centerMode: false,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 2000,
          cssEase: "linear",
        },
      },
    ],
  };

  const handleClickPrev = () => {
    slider.current?.slickPrev();
  };

  const handleClickNext = () => {
    slider.current?.slickNext();
  };

  return (
      <Section>
        <Container>
        {/* Slider */}
        <div style={{ width: "100%", position: "relative" }}>
          <SwiperContainer {...settings} ref={slider}>
            {images.map((image, index) => (
              // Slide
              <ImageContainer key={index} >
                <ImageSwiper
                  src={`${baseURL}/${image}`}
                  alt="image restaurant"
                />
              </ImageContainer>
            ))}
          </SwiperContainer>
             
              <ButtonWrapperLeft>
                <Button.Position
                  position={"left"}
                  name="prev"
                  className="prev"
                  onClick={handleClickPrev}
                />
              </ButtonWrapperLeft>
            
            <ButtonWrapperRight>
              <Button.Position
                position={"right"}
                name="next"
                className="next"
                onClick={handleClickNext}
              />
            </ButtonWrapperRight>
          {!isMinScreen1000 && (
            <StyledButtonWrapperMob>
              <MiddleLine />
              <Styled.FlexWrapper gap={22}>
                <Button.Position
                  position={"left"}
                  name="prev"
                  className="prev"
                  onClick={handleClickPrev}
                />
                <Button.Position
                  position={"right"}
                  name="next"
                  className="next"
                  onClick={handleClickNext}
                />
              </Styled.FlexWrapper>
            </StyledButtonWrapperMob>
          )}
        </div>
        </Container>
      </Section>
  );
};
