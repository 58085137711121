import { EColors } from 'common/styles'
import { Styled } from 'components/Styled'
import styled, { css } from 'styled-components'
import { TStyledButton } from './types'

export const ButtonText = styled(Styled.ButtonText)<TStyledButton>`
  position: relative;

  color: ${EColors.neutral_600};

  transition: all 0.2s ease;
 @media screen and (max-width:768px) {
    font-size: 14px;
  }
  &:after {
    display: block;
    content: '';
    width: 0%;
    height: 1px;
    background: ${EColors.primary_500};
    position: absolute;
    bottom: -4px;
    left: 0;
    transition: all 0.2s ease;
  }

  /* Not disabled */
  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;

      color: ${EColors.primary_500};

      &:hover,
      :active {
        color: ${EColors.neutral_800};

        &:after {
          width: 100%;
          background: ${EColors.neutral_800};
        }
      }
    `}
     @media screen and (max-width:767px) {
    font-size: 14px;
  }
`
