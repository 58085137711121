import { Png, Svg } from "common/assets";
import { EColors } from "common/styles";
import { Styled } from "components/Styled";
import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  height: 100vh;
  min-height: 900px;
  background-color: ${EColors.bg_light};
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    min-height: auto;
  }
  @media screen and (min-width: 1000px) and (max-width: 1350px) {
    min-height: 964px;
  }
`;

export const MottoInfoWrapper = styled.div`
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  opacity: 0;
  transform: translateY(-100%);
  animation: appearence 0.5s linear 0.3s forwards;
  @media screen and (min-width: 1000px) {
    position: relative;
    align-items: flex-end;
  }
  @media screen and (min-width: 1000px) and (max-width: 1350px) {
    gap: 24px;
  }
  @media screen and (min-width: 1351px) {
    gap: 50px;
  }
  @media screen and (min-width: 1440px) {
    padding: 0 72px;
  }
  @keyframes appearence {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const MottoInfo = styled(Styled.PBody1)`
  width: 100%;
  max-width: 526px;
  color: ${EColors.neutral_800};
`;

export const ButtonDown = styled.button`
  border: 1px solid ${EColors.primary_500};
  border-radius: 400px;
  width: 128px;
  height: 128px;
  background-color: transparent;
  background-image: url(${Svg.ArrowDown});
  background-repeat: no-repeat;
  background-size: 33.3px 48.8px;
  background-position: 50% 50%;
  opacity: 0;
  cursor: pointer;
  transition: all 0.4s linear;
  animation: appearance 2s linear 0.2s forwards;
  /*commit if there is block LoadApp */
  /* margin: 0 auto;
  margin-top:34px; */
  &:hover {
    border-color: transparent;
  }

  @media screen and (min-width: 1000px) and (max-width: 1350px) {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    transform: translate(-50%);
    margin-top: 16px;
  }
  @media screen and (min-width: 1351px) {
    position: absolute;
    bottom: -26px;
    left: 50%;
    transform: translate(-50%);
    margin-top: 0;
  }
  @media screen and (max-width: 1439px) {
    width: 100px;
    height: 100px;
  }
  @keyframes appearance {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const ContantContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url(${Png.BgParajanov});
  background-repeat: no-repeat;
  background-position: 72px bottom;
  padding: 24px 72px 0;
  @media screen and (min-width:768px) and (max-width: 1189px) { 
    padding: 114px 72px 0;
     justify-content: flex-start;
  }
  @media screen and (max-width: 1439px) { 
    background-size: 25vw;
  }
  @media screen and (max-width: 767px) {
    padding: 120px 16px 0;
    background-position: 0px bottom;
    background-size: 192px;
  }
 
`;

export const ButtonsContainer = styled.div`
  display: flex;
  @media screen and (max-width: 999px) {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 36px;
    gap: 36px;
  }
  @media screen and (min-width: 1000px) {
    position: relative;
  }
`;
