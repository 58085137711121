import { Svg } from "common/assets";
import { TPolitics } from "models";
import { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import { CloseButton, PoliticsWrapper, StyledTextContent } from "./styles";
import { TPropsPolitics } from "./types";

export const Politics = ({ onBackClick, politics }: TPropsPolitics) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const language = localStorage.getItem("Localization") ?? "uk";
  const createMarkup = (pol: TPolitics, lang: string) => {
    return { __html: `${lang === "uk" ? pol[0].value : pol[1].value}` };
    };
    
    useEffect(() => {
        setTimeout(() => {
            setIsVisible(true);
        }, 0);
    }, [isVisible]);

  return (
      <PoliticsWrapper style={{ opacity: isVisible ? 1 : 0 }}>
      <Fade triggerOnce>
        <CloseButton onClick={onBackClick}>
          <img src={Svg.Cross} alt="icon cross"/>
        </CloseButton>
        {politics && (
          <StyledTextContent
            dangerouslySetInnerHTML={createMarkup(politics, language)}
          />
        )}
      </Fade>
    </PoliticsWrapper>
  );
};
