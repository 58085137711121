import { Png } from 'common/assets'
import { useTranslation } from 'react-i18next'
import { Container,  FranchiseText, FranchiseWrapper } from './styled'

export const Franchise = () => {
  const { t } = useTranslation()

  return (
    <>
      <Container>
        <FranchiseWrapper>
          <FranchiseText>{t('franchise')}</FranchiseText>
        </FranchiseWrapper>
      </Container>
    </>
  )
}
