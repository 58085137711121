import { useState, useEffect, useMemo } from 'react';
import { MediaBreakpoints } from '../../helpers';
import {
    TUseWindowDimensionsHook,
    TConditionDimensions,
    TWindowDimensions,
} from './types';


const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
};

export const useWindowDimensions: TUseWindowDimensionsHook = () => {
    const [windowDimensions, setWindowDimensions] = useState<TWindowDimensions>(
        getWindowDimensions(),
    );

    const conditions = useMemo<TConditionDimensions>(
        () => ({
            isMD: windowDimensions.width >= MediaBreakpoints.md,
        }),
        [windowDimensions.width],
    );

    useEffect(() => {
 
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return {
        ...windowDimensions,
        ...conditions,
    };
};
